import React, { useState } from "react";
import { PageProps } from "gatsby";
import { CognitoUser } from "amazon-cognito-identity-js";

import H1 from "../../components/htmlElements/h1";
import Layout from "../../components/layout";
import UserPool from "../../components/auth/UserPool";
import InputWrapper from "../../components/submitElements/inputWrapper";
import Button from "../../components/htmlElements/button";

export default function ActivatePage(ActivatePageProps: PageProps) {
  const [status, setStatus] = useState("");
  const [verificationCode, setVerificationCode] = useState(
    ActivatePageProps.id || ""
  );
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const user = new CognitoUser({
      Username: emailAddress,
      Pool: UserPool,
    });

    user.confirmRegistration(verificationCode, false, (err, result) => {
      setStatus(`Thank you for activating your account. You can login now.`);
    });
  };

  const displayError = errorMessage ? (
    <div className="mt-4 border border-red/50 bg-red/10 p-1 pl-2 text-sm rounded">
      <p>{errorMessage}</p>
    </div>
  ) : (
    ""
  );

  return (
    <Layout>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-7">
          <H1 innerContent="Activate your account" additionalClasses="pb-5" />

          {status && status !== "" ? (
            <p>{status}</p>
          ) : (
            <>
              <form className="mt-5">

              {displayError}

              <p className="mb-2">Please check your email for the verification code we've sent you.</p>

              <InputWrapper title="Verification code">
                <input
                  type="text"
                  name="verification_code"
                  className="border border-gray-2 rounded px-2 py-2 font-sm   w-full  focus:outline-none focus:border-transparent"
                  required
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                />
              </InputWrapper>

                <InputWrapper title="Email address:">
                  <input
                    type="text"
                    className="border border-gray-2 rounded px-2 py-2 font-sm   w-full  focus:outline-none focus:border-transparent"
                    required
                    value={emailAddress}
                    placeholder="john@doe.com"
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                </InputWrapper>

                <InputWrapper title="Password">
                  <input
                    type="password"
                    name="password"
                    className="border border-gray-2 rounded px-2 py-2 font-sm   w-full  focus:outline-none focus:border-transparent"
                    required
                    value={password}
                    placeholder="*********"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputWrapper>

                <Button innerContent="Activate" color="blue" type="submit" onClick={handleSubmit} />
              </form>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}
